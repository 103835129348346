import * as React from "react";
import { useLocation } from "@reach/router";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import Footer from "../components/Footer/Footer";

import "./LegalPage.css";

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../context/GlobalContextProvider";

const ContactPage = ({ pageContext }) => {
  const dispatch = React.useContext(GlobalDispatchContext);

  return (
    <Page>
      <TopBar showSearch={true} hideCurrency={true} />
      <div style={{ maxWidth: 1000, margin: "0 auto", minHeight: "60vh" }}>
        <Section>
          <SectionHeading>Contact Us | AI Product Reviews</SectionHeading>
          <div style={{ maxWidth: 900 }}>
            <p>
              Hello from the Ai Product Reviews team! Thanks for visiting our
              website, we hope you can find the perfect tool for what you need!
            </p>
            <p>
              If you have any questions or suggestions, please get in touch. You
              can fill out the form on this page or email us at{" "}
              <a
                href="mailto:info@productreviews.com"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                info@productreviews.com
              </a>
              . We're here to help.
            </p>
            <p>
              We always try to answer queries within 24 working hours but
              sometimes we might take a bit longer so please kindly be patient.
            </p>
            <p>
              Here are links to our social media pages:{" "}
              <a
                href="https://www.facebook.com/people/AI-Product-Reviews/61550318561591/"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                Facebook
              </a>
              ,{" "}
              <a
                href="https://twitter.com/ai_reviews_pk"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                Twitter
              </a>
              ,{" "}
              <a
                href="https://www.instagram.com/ai_product_reviews/"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                Instagram
              </a>
              ,{" "}
              <a
                href="https://www.youtube.com/@aiproductreviews"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                Youtube
              </a>{" "}
              and{" "}
              <a
                href="https://www.linkedin.com/company/96024609/admin/feed/posts/"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                LinkedIn
              </a>
            </p>
            <p>
              If you have a business related enquiry please use the following
              email address:
            </p>
            <p>
              <a
                href="mailto:business@ai-productreviews.com"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                business@ai-productreviews.com
              </a>
            </p>
            <p>
              If you have any noticed any issues with our website please kindly
              report to:
            </p>
            <p>
              <a
                href="mailto:tech@ai-productreviews.com"
                target="_blank"
                rel="noopener"
                className="underline"
              >
                tech@ai-productreviews.com
              </a>
            </p>
          </div>
        </Section>
      </div>
      <Footer />
    </Page>
  );
};

export default ContactPage;

export const Head = () => {
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={"Contact Us | AI Product Reviews"}
      description={
        "Reach out for inquiries, feedback, or collaboration. Either way we'd love to hear from you so please don't be shy!"
      }
      pathname={location.pathname}
    />
  );
};
